import React, { useState, useEffect } from "react";
import "./Teszt.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Teszt() {
  const [questions, setQuestions] = useState([
    { question: "", answers: [{ text: "", isCorrect: false }] },
  ]);
  const [tests, setTests] = useState([]); // Tesztek állapota
  const customId = "custom-id-yes";

  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");

  // Kérdés változtatás kezelése
  const handleQuestionChange = (index, event) => {
    const newQuestions = [...questions];
    newQuestions[index].question = event.target.value;
    setQuestions(newQuestions);
  };

  // Válasz változtatás kezelése
  const handleAnswerChange = (qIndex, aIndex, event) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].answers[aIndex].text = event.target.value;
    setQuestions(newQuestions);
  };

  // Helyes válasz checkbox kezelése
  const handleCorrectAnswerChange = (qIndex, aIndex, event) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].answers[aIndex].isCorrect = event.target.checked;
    setQuestions(newQuestions);
  };

  // Új kérdés hozzáadása
  const addQuestion = () => {
    setQuestions([
      ...questions,
      { question: "", answers: [{ text: "", isCorrect: false }] },
    ]);
  };

  // Új válasz hozzáadása
  const addAnswer = (index) => {
    const newQuestions = [...questions];
    newQuestions[index].answers.push({ text: "", isCorrect: false });
    setQuestions(newQuestions);
  };

  // Kurzusok és tesztek lekérése az API-ból
  useEffect(() => {
    // Kurzusok lekérése
    fetch("https://academy.slimstore.hu/api/get_courses.php")
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          toast.error("Hiba történt a kurzusok lekérésekor!", {
            position: "bottom-center",
            toastId: customId,
            theme: "colored",
          });
        } else {
          const sortedCourses = data.sort((a, b) => {
            if (a.category < b.category) return -1;
            if (a.category > b.category) return 1;
            if (a.coursename < b.coursename) return -1;
            if (a.coursename > b.coursename) return 1;
            return 0;
          });
          setCourses(sortedCourses);
        }
      })
      .catch((error) => {
        console.error("Hiba történt:", error);
        toast.error("Szerverhiba! Nem sikerült a kurzusok lekérése.", {
          position: "bottom-center",
          toastId: customId,
          theme: "colored",
        });
      });

    // Tesztek lekérése
    fetch("https://academy.slimstore.hu/api/get_tests.php")
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setTests(data.tests);
        } else {
          toast.error("Hiba történt a tesztek lekérésekor!", {
            position: "bottom-center",
            toastId: customId,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        console.error("Hiba történt:", error);
        toast.error("Szerverhiba! Nem sikerült a tesztek lekérése.", {
          position: "bottom-center",
          toastId: customId,
          theme: "colored",
        });
      });
  }, []);

  // Teszt törlése
  const deleteTest = (id) => {
    fetch("https://academy.slimstore.hu/api/delete_test.php", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          toast.success("Teszt sikeresen törölve!", {
            position: "bottom-center",
            toastId: customId,
            theme: "colored",
          });
          setTests(tests.filter((test) => test.id !== id)); // Frissítés
        } else {
          toast.error("Hiba történt a teszt törlésekor!", {
            position: "bottom-center",
            toastId: customId,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        console.error("Hiba történt:", error);
        toast.error("Szerverhiba! Nem sikerült a teszt törlése.", {
          position: "bottom-center",
          toastId: customId,
          theme: "colored",
        });
      });
  };

  // Teszt mentése
  const saveTest = () => {
    if (!selectedCourse) {
      toast.error("Válassz egy kurzust a teszt mentéséhez!", {
        position: "bottom-center",
        toastId: customId,
        theme: "colored",
      });
      return;
    }

    const testData = {
      course_id: selectedCourse,
      questions: questions,
    };

    console.log("Elküldött adat:", testData); // Kiírja az elküldött adatokat

    // Küldjük el az adatokat a backendnek
    fetch("https://academy.slimstore.hu/api/save_test.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(testData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          toast.success("Teszt sikeresen elmentve!", {
            position: "bottom-center",
            toastId: customId,
            theme: "colored",
          });
          setQuestions([
            { question: "", answers: [{ text: "", isCorrect: false }] },
          ]); // Teszt űrlap alaphelyzetbe állítása
        } else {
          toast.error("Hiba történt a teszt mentésekor!", {
            position: "bottom-center",
            toastId: customId,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        console.error("Hiba történt:", error);
        toast.error("Szerverhiba! Nem sikerült a teszt mentése.", {
          position: "bottom-center",
          toastId: customId,
          theme: "colored",
        });
      });
  };

  // Kiválasztott kurzus kezelése
  const handleCourseSelect = (e) => {
    setSelectedCourse(e.target.value);
  };

  // Tesztek szűrése a kiválasztott kurzus szerint
  const filteredTests = selectedCourse
    ? tests.filter(
        (test) => parseInt(test.course_id) === parseInt(selectedCourse)
      )
    : tests; // Ha nincs kurzus kiválasztva, minden tesztet megjelenítünk

  return (
    <div className="teszt-container">
      <ToastContainer /> {/* Toastify konténer a visszajelzésekhez */}
      {/* Kurzus kiválasztása */}
      <div className="select-course">
        <label htmlFor="courseid">Válassz egy kurzust:</label>
        <select
          id="courseid"
          value={selectedCourse}
          onChange={handleCourseSelect}
        >
          <option value="">Válassz egy kurzust</option>
          {courses.map((course) => (
            <option key={course.id} value={course.id}>
              {course.category + " - " + course.coursename}
            </option>
          ))}
        </select>
      </div>
      {/* Teszt kérdések kezelése */}
      <div className="teszt-form">
        <h2>Teszt kérdések kezelése</h2>
        {questions.map((q, qIndex) => (
          <div key={qIndex} className="form-group">
            <label>Kérdés:</label>
            <input
              type="text"
              value={q.question}
              onChange={(event) => handleQuestionChange(qIndex, event)}
              placeholder="Kérdés megadása"
            />
            <h4>Válaszok:</h4>
            {q.answers.map((a, aIndex) => (
              <div key={aIndex} className="form-group answer-group">
                <input
                  type="text"
                  value={a.text}
                  onChange={(event) =>
                    handleAnswerChange(qIndex, aIndex, event)
                  }
                  placeholder="Válasz megadása"
                />
                <label className="correct-answer-checkbox">
                  <input
                    type="checkbox"
                    checked={a.isCorrect}
                    onChange={(event) =>
                      handleCorrectAnswerChange(qIndex, aIndex, event)
                    }
                  />
                  Helyes válasz
                </label>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addAnswer(qIndex)}
              className="submit-btn"
            >
              Új válasz hozzáadása
            </button>
          </div>
        ))}
        <button type="button" onClick={addQuestion} className="submit-btn">
          Új kérdés hozzáadása
        </button>
        <button type="button" onClick={saveTest} className="submit-btn">
          Teszt mentése
        </button>
      </div>
      {/* Tesztek táblázat */}
      <div className="test-list">
        <h2>Felvitt tesztek</h2>
        {filteredTests.length === 0 ? (
          <p>Nincs teszt ehhez a kurzushoz.</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Kérdés</th>
                <th>Válaszok</th>
                <th>Műveletek</th>
              </tr>
            </thead>
            <tbody>
              {filteredTests.map((test) => (
                <tr key={test.id}>
                  <td>{test.id}</td>
                  <td>{test.question}</td>
                  <td>
                    {JSON.parse(test.answers).map((answer, index) => (
                      <div key={index}>
                        {answer.text} (
                        {answer.isCorrect ? "Helyes" : "Helytelen"})
                      </div>
                    ))}
                  </td>
                  <td>
                    <button
                      onClick={() => deleteTest(test.id)}
                      className="delete-btn"
                    >
                      Törlés
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

export default Teszt;
