import React from "react";
import CourseCard from "./CourseCard";
import Sidebar from "./Sidebar";
import logo from "../slimuniversity.png";
import Navbar from "./Navbar"; // Add this import
import { useNavigate } from "react-router-dom";
import "../App.css";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode"; // Helyes import
import { computeHeadingLevel } from "@testing-library/react";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Chart.js regisztrálása
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = ({ onLogout }) => {
  const [courses, setCourses] = useState([]);

  const navigate = useNavigate();

  const username = localStorage.getItem("username");
  const email = localStorage.getItem("email");
  const fullname = localStorage.getItem("fullname");
  const usertype = localStorage.getItem("usertype");
  const isslimfigther = localStorage.getItem("isslimfigther");
  const [selectedYearMonth, setSelectedYearMonth] = useState(
    `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(
      2,
      "0"
    )}`
  ); // Alapértelmezett az aktuális év-hónap
  const actualMonth = new Date().getMonth() + 1;
  const [statisticsForUsers, setStatisticsForUsers] = useState();
  const [chartData, setChartData] = useState(null);
  console.log("szutykospunvi", isslimfigther);
  localStorage.setItem(
    "actualmonth",
    `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(
      2,
      "0"
    )}`
  );

  console.log("username", username, selectedYearMonth);

  const fetchData2 = async () => {
    try {
      const response = await axios.get(
        "https://academy.slimstore.hu/api/getcourses.php"
      );
      const data = Array.isArray(response.data) ? response.data : []; // Ha a válasz nem tömb, akkor üres tömböt állítunk be
      console.log("kurzusdarab", response.data);

      setCourses(data);
    } catch (error) {
      console.error("Hiba történt az adatok lekérdezése során:", error);
    }
  };
  useEffect(() => {
    console.log("Lekért kurzusok:", courses);
  }, [courses]);
  // Használjuk az useEffect-et, hogy a komponens betöltődésekor lekérjük az adatokat
  useEffect(() => {
    fetchData2();
  }, []);

  useEffect(() => {
    const refreshAccessToken = async () => {
      const token = localStorage.getItem("jwt");
      const refreshToken = localStorage.getItem("refreshToken");
      if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp < currentTime) {
          // Token lejárt, megújítás szükséges
          try {
            const response = await axios.post(
              "https://academy.slimstore.hu/api/refresh.php",
              {
                token: refreshToken,
              }
            );
            localStorage.setItem("jwt", response.data.accessToken);
            console.error("Success to refresh token", response);
            console.log("tokenlejar", response);
          } catch (error) {
            console.log("tokenlejar2");
            console.error("Failed to refresh token", error);
            onLogout();
            navigate("/login");
          }
        }
      }
    };

    const interval = setInterval(() => {
      refreshAccessToken();
    }, 5 * 60 * 1000); // 5 percenként ellenőrizzük a token érvényességét

    return () => clearInterval(interval); // Tisztítás az effectből való kilépéskor
  }, [onLogout, navigate]);

  useEffect(() => {
    const yearMonth = selectedYearMonth;
    fetch(
      `https://academy.slimstore.hu/api/monthly_tests_by_user.php?yearMonth=${yearMonth}`
    )
      .then((response) => {
        if (!response.ok) {
          // Hálózati vagy szerverhiba ellenőrzése
          throw new Error("Hálózati hiba történt: " + response.statusText);
        }
        return response.json(); // JSON formátum feldolgozása
      })
      .then((data) => {
        if (data.success) {
          console.log("A PHP válasza (JSON):", data.data);

          const labels = data.data.map(
            (item) => `${item.Felhasználónév} (${item.Kurzus})`
          );
          const datasetValues = data.data.map((item) =>
            parseInt(item["Próbálkozások száma"], 10)
          ); // Próbálkozások száma

          // Állapot frissítése
          setChartData({
            labels,
            datasets: [
              {
                label: "Próbálkozások száma",
                data: datasetValues,
                backgroundColor: "rgba(75, 192, 192, 0.6)", // Oszlop színe
                borderColor: "rgba(75, 192, 192, 1)", // Keretszín
                borderWidth: 1,
              },
            ],
          });
        } else {
          console.error(
            "Hiba az SQL lekérdezésben vagy nincs adat:",
            data.error
          );
        }
      })
      .catch((error) => {
        console.error("Hiba történt a kérés során:", error);
      });
  }, [selectedYearMonth]);

  const getYearMonths = () => {
    const currentYear = new Date().getFullYear();
    const months = [];
    for (let year = currentYear - 1; year <= currentYear; year++) {
      for (let month = 1; month <= 12; month++) {
        const formattedMonth = String(month).padStart(2, "0");
        months.push(`${year}-${formattedMonth}`);
      }
    }
    return months;
  };

  if (!chartData) {
    return <div>Töltés...</div>;
  }

  return (
    <div className="dashboard">
      {/*    <Navbar onLogout={onLogout} />
            <Sidebar /> */}
      <div className="content">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <h2>
          Elérhető kurzusok({courses.length}) Üdv az oldalton {username}
        </h2>
        <div className="course-grid">
          {/*   {" "}
          {courses.map((course) => (
            <CourseCard key={course.courseid} course={course} />
          ))} */}

<div
  style={{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    textAlign: "center",
    margin: "20px auto",
  }}
>
  <select
    value={selectedYearMonth}
    onChange={(e) => setSelectedYearMonth(e.target.value)}
  >
    {getYearMonths().map((yearMonth) => (
      <option key={yearMonth} value={yearMonth}>
        {yearMonth}
      </option>
    ))}
  </select>
</div>    
        </div>

        <div className="chart-container">
          <div>
            <h2>Havi Próbálkozások Kurzusonként</h2>
            <Bar
  data={chartData}
  options={{
    responsive: true, // Reszponzív viselkedés
    maintainAspectRatio: true, // Az arány megőrzése
    aspectRatio: 2, // Arány (2:1 szélesség:magasság)
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Kurzus próbálkozások (${selectedYearMonth})`,
      },
    },
    scales: {
      x: {
        ticks: {
          maxRotation: 45, // Forgatás kisebb kijelzőkön
          minRotation: 0,
        },
      },
      y: {
        beginAtZero: true, // Y tengely nulláról indul
      },
    },
  }}
/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
