import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';
import { computeHeadingLevel } from '@testing-library/react';
import Sidebar from './Sidebar';

const Navbar = ({ onLogout }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleLogoutClick = () => {
    console.log("onLogout", onLogout);
    if (typeof onLogout === 'function') {
      onLogout();
      navigate('/login');
    } else {
      console.error("onLogout is not a function");
    }
  };

  // Bezárja a menüt, ha bárhová máshová kattintasz
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.dropdown-menu') && !event.target.closest('.menu-icon')) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar">

      <Sidebar></Sidebar>
      <div className="logo"></div> {/* Logo balra kerül */}
      <div className="menu-icon" onClick={toggleMenu}>
        &#9776; {/* Hamburger ikon, amely jobbra igazítva jelenik meg */}
      </div>
      {isMenuOpen && (
        <ul className="dropdown-menu">
          <li>Profile</li>
          <li>Settings</li>
          <li onClick={handleLogoutClick}>Logout</li> {/* Kijelentkezési menüpont */}
        </ul>
      )}
    </nav>
  );
};

export default Navbar;