import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import logo from "../slimuniversity.png";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    const params = {
      username: username,
      password: password,
    };

    try {
      const response = await axios.post(
        "https://academy.slimstore.hu/api/login.php",
        params,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        localStorage.setItem("jwt", response.data.accessToken); // JWT token mentése
        localStorage.setItem("refreshToken", response.data.refreshToken); // Refresh token mentése

        // JWT token dekódolása a felhasználói adatok eléréséhez
        try {
          const decodedToken = jwtDecode(response.data.accessToken);
          localStorage.setItem("username", decodedToken.username);
          localStorage.setItem("email", decodedToken.email);
          localStorage.setItem("fullname", decodedToken.fullname);
          localStorage.setItem("usertype", decodedToken.usertype);
          localStorage.setItem("isslimfigther", decodedToken.isslimfigther);
          localStorage.setItem("id", decodedToken.sub);
          console.log("id", decodedToken.sub);

          localStorage.setItem("isUserLoggedIn", true); // Bejelentkezési állapot mentése

          setError(null); // Hibák törlése sikeres bejelentkezés után
          onLogin();
          navigate("/dashboard"); // Átirányítás a dashboardra
        } catch (decodeError) {
          setError("Hiba a token dekódolása során!");
          console.log("Decode Error:", decodeError);
        }
      } else {
        setError("Hibás felhasználónév vagy jelszó");
      }
    } catch (error) {
      setError("Hiba történt a bejelentkezés során! " + error.message);
      console.log("Error:", error);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin} className="login-form">
        <img src={logo} alt="Logo" className="logo" />
        <div className="form-group">
          <label>Felhasználónév:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Jelszó:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {error && <p className="error">{error}</p>}
        <button type="submit" className="login-button">
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
