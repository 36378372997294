import React, { useEffect, useRef, useState } from "react";

const Timer = ({ initialTime, onComplete }) => {
  const [displayTime, setDisplayTime] = useState(initialTime);
  const timeLeft = useRef(initialTime);
  const timerRef = useRef(null);

  useEffect(() => {
    if (timeLeft.current > 0) {
      timerRef.current = setInterval(() => {
        timeLeft.current -= 1;
        setDisplayTime(timeLeft.current);

        if (timeLeft.current <= 0) {
          clearInterval(timerRef.current);
          if (onComplete) onComplete();
        }
      }, 1000);
    }

    return () => {
      clearInterval(timerRef.current);
    };
  }, [onComplete]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div style={{ fontSize: "24px", textAlign: "center", margin: "20px 0" }}>
      <p>Hátralévő idő a következő tesztig:</p>
      <p>{formatTime(displayTime)}</p>
    </div>
  );
};

export default Timer;
