import React from "react";
import "./Modal.css";

function Modal({ show, onClose, video }) {
  if (!show) {
    return null;
  }

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        <h2>Video Details</h2>
        <p>
          <strong>Title:</strong> {video.title}
        </p>
        <p>
          <strong>Description:</strong> {video.description}
        </p>
        <p>
          <strong>Video URL:</strong>{" "}
          <a href={video.videourl} target="_blank" rel="noopener noreferrer">
            {video.videourl}
          </a>
        </p>
        <p>
          <strong>Visible to:</strong> {video.watchfor}
        </p>
        <button className="close-btn" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
}

export default Modal;
