import React, { useState } from "react";
import "./Sidebar.css";
import Cap from "../cap.png";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const username = localStorage.getItem("username");
  const email = localStorage.getItem("email");
  const fullname = localStorage.getItem("fullname");
  const usertype = localStorage.getItem("usertype");
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setIsOpen(false); // Bezárja a sidebar-t navigáció után
  };

  return (
    <>
      <div
        className="hamburger-menu"
        onClick={toggleSidebar}
        aria-expanded={isOpen}
        aria-controls="sidebar"
      >
        <img src={Cap} alt="capicon" /> {/* Itt használjuk az új SVG ikont */}
      </div>
      <div id="sidebar" className={`sidebar ${isOpen ? "open" : ""}`}>
        <nav>
          <ul>
            <li>
              <button onClick={() => handleNavigation("/dashboard")}>
                Dashboard
              </button>
            </li>
            {usertype === "admin" ? (
              <li>
                <button onClick={() => handleNavigation("/admin")}>
                  Admin
                </button>
              </li>
            ) : null}

            <li>
              <button onClick={() => handleNavigation("/course")}>
                Kurzus
              </button>
            </li>
          </ul>
        </nav>
      </div>
      {isOpen && <div className="overlay" onClick={toggleSidebar}></div>}
    </>
  );
};

export default Sidebar;
