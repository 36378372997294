import React, { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "videojs-youtube";
import "video.js/dist/video-js.css";


const CourseCard = ({ course }) => {
  const videoNode = useRef(null);
  const player = useRef(null);
  const initialized = useRef(false);
  const [playedTime, setPlayedTime] = useState(0); // Állapot a lejátszott idő követésére
  const playStartTime = useRef(null); // A lejátszás kezdési idejének követésére

 let modofidedCourseUrl=course.videourl.replace("/watch","/embed/")
  //var embedUrl = generateEmbedUrl(course.videourl);

  //generateEmbedUrl(course.videourl);
console.log("modofidedCourseUrl",modofidedCourseUrl)
  useEffect(() => {
    if (videoNode.current && !initialized.current) {
      initialized.current = true;
      player.current = videojs(videoNode.current, {
        controls: true,
        fluid: true,
        responsive: true,
        techOrder: ['youtube'],
        sources: [
          {
            type: "video/youtube",
            src: course.videourl
          }
        ],
        youtube: {
          modestbranding: 1,
          rel: 0,
          showinfo: 0,  // Régebbi opció, de sokszor még hatásos
          iv_load_policy: 3,  // Kikapcsolja az anotációkat
          disablekb: 1  // Kikapcsolja a billentyűzetes vezérlést
        },
        pointerevents: "none"
        
      }).ready(function () {
        console.log("Player Ready");

        // `play` esemény hallgatása
        this.on('play', function () {
          playStartTime.current = this.currentTime();
        });

        // `pause` esemény hallgatása
        this.on('pause', function () {
          if (playStartTime.current !== null) {
            const timeWatched = this.currentTime() - playStartTime.current;
            setPlayedTime(prevTime => prevTime + timeWatched);
            playStartTime.current = null;
          }
        });

        // `ended` esemény hallgatása (amikor a videó véget ér)
        this.on('ended', function () {
          if (playStartTime.current !== null) {
            const timeWatched = this.currentTime() - playStartTime.current;
            setPlayedTime(prevTime => prevTime + timeWatched);
            playStartTime.current = null;
          }
        });
      });
    }

    return () => {
      if (player.current) {
        player.current.dispose();
      }
    };
  }, []);
  useEffect(()=>{
    console.log("lejatszottido",playedTime)
  })
  return (
    <div className="course-card">
      <div className="course-image">
       
        
        <video ref={videoNode} className="video-js" />
      
      </div>
      <div className="course-details">
        <h3>{course.title}</h3>
        <p>{course.description}</p>
        <p>Lejátszott idő: {playedTime.toFixed(2)} másodperc</p>
      </div>
    </div>
  );
};

export default CourseCard;
